import React from "react"
import { Link } from "gatsby"

const Sr = () => {
  
  return(
    <div className="container my-5 pt-5">
      <p className="h3 my-4">
        µTorrent is a TRON Super Representative
      </p>
      <p>
        As a Super Representative elected by the TRON community in 2018, µTorrent is proud to provide part of the infrastructure to meet the demands of the TRON ecosystem of decentralized apps and services of the future.
      </p>
      <p>
        µTorrent has a longstanding history of providing lightweight, fast, decentralized torrent download software for <Link to="/downloads/win/">Windows</Link>, <Link to="/downloads/mac/">Mac</Link> and <Link to="/downloads/android/">Android</Link> devices. Our team comprises some of the original custodians of the BitTorrent protocol and is still active in the open source community to enhance its legacy. Like TRON, we share a vision of a decentralized internet where thousands of DApps can flourish on a stable blockchain. To make that vision a reality, µTorrent is one of 27 TRON Super Representatives that help maintain a stable and reliable decentralized network for the TRON community. 
      </p>

      <p className="h3 my-4">
        Why us?
      </p>
      <p>
        We run an extensive infrastructure to support our massive worldwide user base. We have servers housed in multiple state of the art data centers around the world with disaster recovery centers in Los Angeles and Iceland.</p>
      <p>
        Available infrastructure for this project <br/>
        CPU 72 Cores, RAM 1TB, SSD: 3T <br/>
        Network: 25 gbs <br/>
        Available IT Budget: $120,000
      </p>
      <p>
        Interested in contacting us? Please send inquiries to sr |at| utorrent |dot| com
      </p>
    </div>
  )
}


export default Sr


